<template>
  <div class="row-1 footer-contacts">
    <div class="footer-contacts__column">
      <h3 class="title-xs">Mazarol Società Cooperativa</h3>
      <ul class="text-s m-top-16px">
        <li>Limana (BL) - Dolomiti Bellunesi</li>
        <li>P.IVA: 00965410251</li>
        <li class="footer-contacts__policy">
          <a
            href="https://www.iubenda.com/privacy-policy/18978830/legal"
            class="link-underline-1"
            target="_blank"
            >Privacy policy</a
          >
        </li>
        <li>
          <a
            href="https://www.iubenda.com/privacy-policy/18978830/cookie-policy"
            class="link-underline-1"
            target="_blank"
            >Cookie policy</a
          >
        </li>
      </ul>
    </div>
    <div class="footer-contacts__column">
      <h3 class="title-xs">{{ $t('footer.contacts') }}</h3>
      <ul class="text-s m-top-16px">
        <li>Tel. 3290040808 - 3299879724</li>
        <li>
          <a href="mailto:guide.pndb@gmail.com" class="link-underline-1"
            >Mail guide.pndb@gmail.com</a
          >
        </li>
        <li>
          <a href="mailto:mazarol2014@legalmail.it" class="link-underline-1"
            >Pec mazarol2014@legalmail.it</a
          >
        </li>
      </ul>
    </div>
    <div class="footer-contacts__column">
      <h3 class="title-xs">Social</h3>
      <ul class="text-s m-top-16px">
        <li>
          <a
            href="https://www.facebook.com/mazarol.net/?fref=ts"
            class="link-underline-1"
            >Facebook</a
          >
        </li>
        <li>
          <a
            href="https://www.instagram.com/mazarolguidenaturalistiche/"
            class="link-underline-1"
            >Instagram</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterContacts',
}
</script>

<script setup></script>

<style lang="scss">
@import './style.scss';
</style>
